import { Route, Routes } from 'react-router-dom';

import MainApp from 'layouts/MainApp';

import Home from 'routes/home';
import Test from 'routes/test';

export default function Router() {
  return (
    <Routes>
      <Route path="" element={<MainApp />}>
        <Route path="" element={<Home />} />
        <Route path="test" element={<Test />} />
      </Route>
    </Routes>
  );
}
