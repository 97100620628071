export default function Home() {
  return (
    <div className="flex flex-auto flex-col items-center">
      <div className="w-full space-y-8 px-4 py-16">
        <div className="mx-auto w-fit text-7xl font-light tracking-widest text-zinc-800">NEWSEO.ME</div>
        <div className="mx-auto w-fit text-3xl font-light text-zinc-400">HOMEPAGE BLOG</div>
      </div>

      {/* >>> MENU WILL BE ADDED >>> */}
      {/* <div className="w-full max-w-screen-xl px-4 py-8">
        <div className="w-full border-y border-zinc-400 py-8">MENU</div>
      </div> */}
      {/* <<< MENU WILL BE ADDED <<< */}

      <div className="flex w-full max-w-screen-xl space-x-4 px-4 sm:px-8">
        <div className="h-96 flex-auto bg-gray-100"></div>
        <div className="w-96 border"></div>
      </div>

      <div className="w-full max-w-screen-xl p-4 sm:p-8 lg:grid lg:grid-cols-3 lg:gap-8">
        <div className="h-96 w-full border-2 border-zinc-600"></div>
        <div className="h-96 w-full border-2 border-zinc-600"></div>
        <div className="h-96 w-full border-2 border-zinc-600"></div>
        <div className="h-96 w-full border-2 border-zinc-600"></div>
        <div className="h-96 w-full border-2 border-zinc-600"></div>
        <div className="h-96 w-full border-2 border-zinc-600"></div>
      </div>
    </div>
  );
}
