import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import reportWebVitals from "utils/reportWebVitals";
import Router from "routes/router";

import "react-toastify/dist/ReactToastify.css";
import "styles/global.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Router />
    <ToastContainer
      pauseOnFocusLoss={false}
      position="top-right"
      autoClose={3000}
      pauseOnHover={false}
    />
  </BrowserRouter>
);

reportWebVitals();
